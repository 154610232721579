@import '../../scss/colors';
footer {
  p {
    text-align: center;
    line-height: 1.2rem;
    vertical-align: middle;
    padding: 2rem 1rem;
    font-size: 1.2rem;
    a {
      line-height: 1.2rem;
      vertical-align: middle;
      transition: color ease 350ms;
      display: inline-block;
      svg {
        height: 2rem;
        width: 2rem;
        transition: fill ease 350ms;
        display: inline-block;
        margin-bottom: -0.3rem;
        use {
          vertical-align: middle;
        }
      }
      &:hover {
        color: $red;
        svg {
          fill: $red;
        }
      }
    }
  }
}
