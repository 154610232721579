@import '../../scss/colors';

/* *********************** Nav Bar ********************** */

nav {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      max-width: 200px;
      img {
        width: 100%;
      }
    }
    .nav-btn {
      a {
        font-family: 'lexend deca';
      }
    }
  }
  padding: 0.5rem 0;

  border-bottom: 1px solid rgba(70, 71, 78, 0.1);
}

@media (max-width: 570px) {
  nav .container {
    .logo {
      width: 150px;
    }
    .nav-btn {
      a {
        span {
          display: none;
        }
      }
    }
  }
}
