/* ******************* Color Variables ****************** */

/* ********************* Main Colors ******************** */

html {
  --red: #d74034;
  --purple: #5b5cf8;
  --white: #f7f8f3;
  --text-white: #f7f8f3;
  --text-darker: #3b4250;
  --text-dark: #8b93a3;
  --break: #fff;
}

html[data-theme='dark'] {
  --white: #0f0717;
  --text-darker: #8b93a3;
  --text-dark: #3b4250;
  --break: #1c1026;
}
$purple: var(--purple);
$white: var(--white);
$text-white: var(--text-white);
$red: var(--red);
$text-dark: var(--text-dark);
$text-darker: var(--text-darker);
$break: var(--break);
