@import '../../scss/colors';

.featured-area {
  max-width: 980px;
  padding-top: 2rem;
  & > div {
    margin-bottom: 2rem;
    border-bottom: 1px #dbdbdb solid;

    &:last-of-type {
      border: none;
    }
  }

  h1.title {
    background-color: $white;

    color: $text-darker;
    position: relative;
    padding: 1rem 0;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 49%;
      height: 2px;
      border-radius: 3px;
      background-color: $red;
      z-index: 1;
    }
    span {
      position: relative;
      background-color: $white;
      z-index: 5;
      padding-right: 1rem;
    }
  }
}
