@import "../../scss/colors";

.featured {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  justify-content: center;
  align-items: center;
  position: relative;

  .detail {
    grid-column: 1/8;
    grid-row: 1/1;
    padding: 2rem 0;
    z-index: 1;
    h2 {
      color: $text-darker;
    }
    p.desc {
      padding: 2rem 10% 2rem 2rem;
      width: 100%;
      background: linear-gradient(to right, #fff 60%, transparent);
      border-radius: 15px;
      a {
        margin-top: 0.5rem;
        display: inline-block;
      }
    }
    p.type {
      font-size: 0.8rem;
      color: $red;
      text-transform: uppercase;
      padding-bottom: 2rem;
    }
    p.tags {
      padding: 2rem 15% 0 0;
      span {
        display: inline-block;
        padding: 0.2rem 0.8rem;
        background-color: $break;
        border-radius: 50px;
        font-size: 0.7em;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        letter-spacing: 0.4px;
        color: #444;
        font-weight: bold;
        box-shadow: 0 0 25px rgba($color: #d74034, $alpha: 0.1);
      }
    }
  }
  .thumbnail {
    grid-column: 7/13;
    grid-row: 1/1;
    border-radius: 15px;
    overflow: hidden;
    background: var(--bg-image) no-repeat center center / contain;
    width: 100%;
    height: 100%;
    animation: focusBlur 500ms cubic-bezier(0.68, -0.1, 0.265, 1.55) forwards;
    img {
      width: 100%;

      opacity: 0;
      // visibility: hidden;
    }
  }
  &:hover {
    .thumbnail {
      animation: blurFocus 500ms cubic-bezier(0.68, -0.1, 0.265, 1.55) forwards;
    }
  }
}
@keyframes blurFocus {
  0% {
    filter: blur(0px);
    opacity: 1;
  }
  50% {
    filter: blur(2px) grayscale(50%);
    background-image: var(--bg-hover);
  }
  to {
    filter: blur(0px);
    background-image: var(--bg-hover);
  }
}
@keyframes focusBlur {
  0% {
    filter: blur(0px);
    opacity: 1;
    background-image: var(--bg-hover);
  }
  50% {
    filter: blur(2px) grayscale(50%);
    background-image: var(--bg-image);
  }
  to {
    filter: blur(0px);
    background-image: var(--bg-image);
  }
}
.featured.rtl {
  .detail {
    grid-column: 6/13;
    text-align: right;
    p.desc {
      background: linear-gradient(to right, transparent, #fff 60%);
      padding: 2rem 2rem 2rem 10%;
    }
    p.tags {
      padding: 2rem 0 0 15%;
    }
  }
  .thumbnail {
    grid-column: 1/7;
  }
}

.svg-icon {
  display: inline-block;
  svg {
    fill: #d74034;
    width: 1.75rem;
    height: 1.75rem;
    vertical-align: middle;
    transition: transform 100ms ease-in-out;
  }

  &:hover {
    svg {
      transform: translateY(-3px);
    }
  }
}

@media (max-width: 570px) {
  .featured,
  .featured.rtl {
    grid-template-columns: 1fr;

    .detail {
      grid-column: 1/2;
      grid-row: 2/3;
      text-align: left;
      p.desc {
        background: linear-gradient(to top, transparent, #fff 80%);
        padding: 2rem;
      }

      p.tags {
        padding: 2rem 0 0;
      }
    }
    .thumbnail {
      grid-column: 1/2;
      grid-row: 1/2;
    }
  }
}
