@import url('https://fonts.googleapis.com/css?family=Lexend+Deca|Poppins&display=swap');

@import 'colors';

/* #### Uitility #### */

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  overflow-x: hidden;
}

body {
  font-family: 'Poppins';
  background-color: $white;
}

.lead {
  font-size: 1.2rem;
  font-weight: 500;
}

ul li {
  list-style: none;
}
p {
  font-family: 'Poppins';
  color: $text-darker;
  line-height: 1.8;
}
a {
  text-decoration: none;
  color: $text-darker;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lexend Deca';
}
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

:focus {
  outline: 2px solid rgba(215, 62, 51, 0.2);
}

.grid-2 {
  display: grid;
  grid-template-columns: 6fr 6fr;
  justify-content: space-between;
  align-items: center;
}

/* **************** CSS for Container *************** */

.container {
  padding: 0 1rem;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }
}

/* ********************** Hero CTA ********************** */
.hero-cta {
  background: $red;
  border-radius: 50px;
  color: $text-white;
  box-shadow: 0 0 25px 0 rgba(215, 62, 51, 0.3);
  padding: 0.5rem 2rem;
  display: inline-block;
  font-size: 1.2rem;
  margin: 1rem 0;
  transition: background 500ms ease, box-shadow 500ms ease;
  &:hover {
    background: darken(#d74034, 5%);
    box-shadow: 0 0px 35px 0 rgba(215, 62, 51, 0.5);
  }
}

/* ********************** Animation ********************* */

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeIn {
  animation-name: fadeIn;
}
.fadeInUp {
  animation-name: fadeInUp;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes bgMove {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}
