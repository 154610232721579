@import '../../scss/colors';

.project {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  box-shadow: 4px 4px 16px #dedede, -4px -4px 16px #ffffff;
  border-radius: 10px;
  transition: transform 350ms cubic-bezier(0.68, -0.1, 0.265, 1.55),
    box-shadow 250ms cubic-bezier(0.68, -0.1, 0.265, 1.55);
  .title-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .folder-icon {
      svg {
        fill: #bdbdbd;
        width: 4rem;
        height: 4rem;
        vertical-align: middle;
        transition: fill 450ms cubic-bezier(0.68, -0.1, 0.265, 1.55);
      }
    }
  }
  h2 {
    font-size: 1.35rem;
    padding-top: 1rem;
    color: $text-darker;
  }
  p.type {
    font-size: 0.8rem;
    color: $red;
    padding-bottom: 1rem;
  }
  p.desc {
    font-size: 0.9rem;
  }

  p.tags {
    padding: 2rem 0 0;
    margin-top: auto;
    span {
      display: inline-block;
      padding: 0.1rem 0.8rem;
      letter-spacing: 0.4px;
      background-color: $break;
      border-radius: 50px;
      font-size: 0.7em;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      color: $text-darker;
      font-weight: bold;
    }
  }
  &:hover {
    transform: translateY(-7px);
    box-shadow: 8px 8px 32px #dedede, -8px -8px 32px #ffffff;
    .title-icon .folder-icon svg {
      fill: $red;
    }
  }
}
