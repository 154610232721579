@import '../../scss/colors';

.project-area {
  max-width: 980px;
  padding-top: 2rem;
  margin-bottom: 4rem;
  .project-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  h1.title {
    background-color: $white;
    position: relative;
    padding: 1rem 0;
    margin-bottom: 3rem;
    text-align: center;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 49%;
      height: 2px;
      border-radius: 3px;
      background-color: $red;
      z-index: 1;
    }
    span {
      position: relative;
      background-color: $white;
      border: 2px solid $white;
      z-index: 5;
      padding: 0 1rem;
      width: 100%;
    }
  }
}

@media (max-width: 970px) {
  .project-area {
    .project-grid {
      grid-template-columns: 1fr 1fr;
    }
  }
}
@media (max-width: 570px) {
  .project-area {
    .project-grid {
      grid-template-columns: 1fr;
    }
    h1.title span {
      padding: 0;
    }
  }
}
